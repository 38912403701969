import { defineStore } from "pinia";
import type { CategoryShort } from "~/types/Post";
interface CmsState {
  categories: CategoryShort[];
  global_styles: string;
}
export const useCmsStore = defineStore("cms", {
  state: (): CmsState => ({
    categories: [],
    global_styles: "",
  }),
  getters: {},
  actions: {
    GetGlobalStyle() {
      $fetch("/px/style.css").then((res) => {
        this.global_styles = res + "";
      });
    },
    ArchivedPost(post_id: number) {
      useApiFetch("/api/user/post-archived", {
        method: "post",
        body: {
          post_id,
        },
      });
    },
    IsArchivedPost(post_id: number) {
      return new Promise((resolve, reject) => {
        useApiFetch("/api/user/post-is-archived", {
          method: "post",
          body: JSON.stringify({post_id})
        })
          .then((res) => {
            return resolve(res);
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
  },
});
